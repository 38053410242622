import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <section className="text-center">
        <img
          alt="Cat and human sitting on a couch"
          className="block w-1/2 mx-auto mb-8"
          src={catAndHumanIllustration}
        />
        <a
            href="https://slack.com/oauth/v2/authorize?client_id=1986264146487.2165243261395&scope=chat:write,files:read,im:history&user_scope="
        >
            <img
            alt="Add to Slack"
            className="block h-6 mx-auto"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
        />
        </a>
        <p className="leading-loose">
          {` `}
          <a
            className="font-bold text-gray-900 no-underline"
            href="https://tailwindcss.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
              Starter of file security on Slack
          </a>

        </p>
      </section>
    </Layout>
  );
}

export default IndexPage;
